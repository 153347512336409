@font-face {
  font-family: "DotGothic16";
  src: local("DotGothic16-Regular"), url("./fonts/DotGothic16-Regular.ttf") format("truetype")

}

.App {
  text-align: center;
  background-image: linear-gradient( #b2b5bc,#282c34);
  color: white;
  font-size: calc(10px + 2vmin);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


